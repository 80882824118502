
const path = '/acid-rain-world/89fc62fa/'; // ---arw book K0---
const arwS1Path = '/acid-rain-world/b5f837c5/'; // ---arw book 1---
const arwS2Path = '/acid-rain-world/arws2/'; // ---arw book 2---

export const bookurl = {
// ---arw book 1---
    'arwS1index': arwS1Path,
    '0': arwS1Path + 'd2b41478',
    '1': arwS1Path + '8415b3ba',
    '2': arwS1Path + '3e2eb0bb',
    '3': arwS1Path + 'a9d56efc',
    '4': arwS1Path + '2b1bcb4f',
    '5': arwS1Path + '502aed16',
    '6': arwS1Path + '435f1b2b',
    '7': arwS1Path + '2fde690d',
    '8': arwS1Path + 'f4473219',
    '9': arwS1Path + '137c47bc',
    '10': arwS1Path + '3e08ae1d',
    'arwS1': {
        '0': arwS1Path + 'd2b41478',
        '1': arwS1Path + '8415b3ba',
        '2': arwS1Path + '3e2eb0bb',
        '3': arwS1Path + 'a9d56efc',
        '4': arwS1Path + '2b1bcb4f',
        '5': arwS1Path + '502aed16',
        '6': arwS1Path + '435f1b2b',
        '7': arwS1Path + '2fde690d',
        '8': arwS1Path + 'f4473219',
        '9': arwS1Path + '137c47bc',
        '10': arwS1Path + '3e08ae1d',
    },
// ---END OF arw book 1---

    // ---arw book 1 S2---
    'arwS2index': arwS2Path,
    'arwS2': {
        '0': arwS2Path + '000',
        '1': arwS2Path + '000',
        '2': arwS2Path + '',
        '3': arwS2Path + '',
        '4': arwS2Path + '',
        '5': arwS2Path + '',
        '6': arwS2Path + '',
        '7': arwS2Path + '',
        '8': arwS2Path + '',
        '9': arwS2Path + '',
        '10': arwS2Path + '',
    },
    // ---arw book 1 S2---


// ---arw book K0---
'0.0': path + 'a89b1859',
'1.1': path + '87ae506b',
'1.2': path + 'dfd23656',
'1.3': path + '37b2682c',
'2.1': path + '24ce593b',
'2.2': path + '09b6c0be',
'2.3': path + '7bf09fd6',
'3.1': path + '84f52949',
'3.2': path + 'a73cdb27',
'3.3': path + '8bd5b21b',
'4.1': path + '41c5bfc7',
'4.2': path + '769834e2',
'4.3': path + '3476f6ea',
'5.1': path + '18d3728f',
'5.2': path + '884826cf'
// ---END OF arw book K0---
};

export const book = {

    'prologue': {"en": "Prologue",         "zh-hk": "序言",          "zh-cn": "序言" },
    'chp0': {"en": "Prologue",         "zh-hk": "序言",          "zh-cn": "序言" },
    'chp1': {    "en": "Chapter 1",        "zh-hk": "第一章",        "zh-cn": "第一章" },
    'chp2': {    "en": "Chapter 2",        "zh-hk": "第二章",        "zh-cn": "第二章" },
    'chp3': {    "en": "Chapter 3",        "zh-hk": "第三章",        "zh-cn": "第三章" },
    'chp4': {    "en": "Chapter 4",        "zh-hk": "第四章",        "zh-cn": "第四章" },
    'chp5': {    "en": "Chapter 5",        "zh-hk": "第五章",        "zh-cn": "第五章" },
    'chp6': {    "en": "Chapter 6",        "zh-hk": "第六章",        "zh-cn": "第六章" },
    'chp7': {    "en": "Chapter 7",        "zh-hk": "第七章",        "zh-cn": "第七章" },
    'chp8': {    "en": "Chapter 8",        "zh-hk": "第八章",        "zh-cn": "第八章" },
    'chp9': {    "en": "Chapter 9",        "zh-hk": "第九章",        "zh-cn": "第九章" },
    'chp10':{    "en": "Chapter 10",       "zh-hk": "第十章",        "zh-cn": "第十章" },
    'chp11':{    "en": "Chapter 11",       "zh-hk": "第十一章",      "zh-cn": "第十一章" },
    'chp12':{    "en": "Chapter 12",       "zh-hk": "第十二章",      "zh-cn": "第十二章" },
    'chp13':{    "en": "Chapter 13",       "zh-hk": "第十三章",      "zh-cn": "第十三章" },
    'chp14':{    "en": "Chapter 14",       "zh-hk": "第十四章",      "zh-cn": "第十四章" },

// ---arw book 1---
    "bookTitle": {
        "en": "Acid Rain World",
        "zh-hk": "酸雨戰爭",
        "zh-cn": "酸雨战争",
    },

    "bookSubTitle": {
        "en": "Season 1: In the Shadow of Ghosts",
        "zh-hk": "第一季：閃靈潛影",
        "zh-cn": "第一季：闪灵潜影",
    },

    '0': {  "en": "Prologue: Desert Dreaming",         "zh-hk": "序言 黃沙塵夢",    "zh-cn": "序言 黄沙尘梦" },
    '1': {  "en": "Shit Coffee",                       "zh-hk": "臭屎咖啡",    "zh-cn": "臭屎咖啡" },
    '2': {  "en": "Not the Agurts You Know",           "zh-hk": "不是你所熟悉的亞格斯",    "zh-cn": "不是你所熟悉的亚格斯" },
    '3': {  "en": "Past and Future",                   "zh-hk": "過去與未來",    "zh-cn": "过去与未来" },
    '4': {  "en": "The Jungle",                        "zh-hk": "叢林",    "zh-cn": "丛林" },
    '5': {  "en": "Flesh-freed Sins",                  "zh-hk": "肉宴",    "zh-cn": "肉宴" },
    '6': {  "en": "The Great Minotaurus Migration",    "zh-hk": "彌諾陶大遷徙",    "zh-cn": "弥诺陶大迁徙" },
    '7': {  "en": "Night Raid",                        "zh-hk": "夜襲",    "zh-cn": "夜袭" },
    '8': {  "en": "Alone",                             "zh-hk": "獨行",    "zh-cn": "独行" },
    '9': {  "en": "Estancia Calling",                  "zh-hk": "伊斯坦西亞的呼喚",    "zh-cn": "伊斯坦西亚的呼唤" },
    '10':{  "en": "Original Sin",                      "zh-hk": "原罪",    "zh-cn": "原罪" },

// ---END OF arw book 1---

// ---arw book 1 S2---

"arwS2": {
    "bookTitle" : {
        "en": "Acid Rain World",
        "zh-hk": "酸雨戰爭",
        "zh-cn": "酸雨战争"
    },
    "bookSubTitle": {
        "en": "Season 2: In the Shadow of Ghosts",
        "zh-hk": "第二季：閃靈潛影",
        "zh-cn": "第二季：闪灵潜影",
    },
    "txtDescription" : {
        "en": "this is description",
        "zh-hk": "yes",
        "zh-cn": "yesyesyes"
    },
    '0': {  "en": "Prologue: Desert Dreaming",         "zh-hk": "序言 黃沙塵夢",    "zh-cn": "序言 黄沙尘梦" },
    '1': {  "en": "Shit Coffee",                       "zh-hk": "臭屎咖啡",    "zh-cn": "臭屎咖啡" },
    '2': {  "en": "Not the Agurts You Know",           "zh-hk": "不是你所熟悉的亞格斯",    "zh-cn": "不是你所熟悉的亚格斯" },
    '3': {  "en": "Past and Future",                   "zh-hk": "過去與未來",    "zh-cn": "过去与未来" },
    '4': {  "en": "The Jungle",                        "zh-hk": "叢林",    "zh-cn": "丛林" },
    '5': {  "en": "Flesh-freed Sins",                  "zh-hk": "肉宴",    "zh-cn": "肉宴" },
    '6': {  "en": "The Great Minotaurus Migration",    "zh-hk": "s2-6",    "zh-cn": "弥诺陶大迁徙" },
    '7': {  "en": "Night Raid",                        "zh-hk": "s2-7",    "zh-cn": "夜袭" },
    '8': {  "en": "Alone",                             "zh-hk": "s2-8",    "zh-cn": "独行" },
    '9': {  "en": "Estancia Calling",                  "zh-hk": "s2-9",    "zh-cn": "伊斯坦西亚的呼唤" },

    '10':{  "en": "Original Sin",                      "zh-hk": "消",    "zh-cn": "消" },
},

// ---END OF arw book 1 S2---

// ---arw book K0 (OLD)---
    '0.0': {    "en": "Prologue",                       "zh-hk": "序章",            "zh-cn": "序章" },
//    '1': {      "en": "Rainstorm at the frontier",      "zh-hk": "暴雨邊疆",        "zh-cn": "暴雨边疆" },
    '1.1': {    "en": "The Taste of Coffee",            "zh-hk": "咖啡的味道",      "zh-cn": "咖啡的味道"   },
    '1.2': {    "en": "The First Battle",               "zh-hk": "初陣",            "zh-cn": "初阵" },
    '1.3': {    "en": "Jazz Blues",                     "zh-hk": "藍調爵士",        "zh-cn": "蓝调爵士" },
//    '2': {      "en": "The Veteran’s Final Mission",    "zh-hk": "老兵的最後任務",  "zh-cn": "老兵的最后任务"   },
    '2.1': {    "en": "A Midnight Assignment",          "zh-hk": "夜半出動",        "zh-cn": "夜半出动"     },
    '2.2': {    "en": "Deer Season",                    "zh-hk": "獵鹿季",       "zh-cn": "猎鹿季"          },
    '2.3': {    "en": "Passing It On",                  "zh-hk": "傳承",        "zh-cn": "传承"            },
//    '3': {      "en": "Nothing Ventured",       "zh-hk": "別呆坐等待",  "zh-cn": "别呆坐等待"       },
    '3.1': {    "en": "Useful or not",          "zh-hk": "有用無用",    "zh-cn": "有用无用"         },
    '3.2': {    "en": "Taking out the trash",   "zh-hk": "清除垃圾",    "zh-cn": "清除垃圾"         },
    '3.3': {    "en": "Tartarus",               "zh-hk": "死獄鬥甲場",    "zh-cn": "死狱斗甲场"     },
//    '4': {      "en": "The Last Order",     "zh-hk": "最後的命令",    "zh-cn": "最后的命令"     },
    '4.1': {    "en": "The code",           "zh-hk": "密碼",        "zh-cn": "密码"            },
    '4.2': {    "en": "Maze in the Sands",  "zh-hk": "沙漠迷宮",    "zh-cn": "沙漠迷宫"         },
    '4.3': {    "en": "Gun Salute",         "zh-hk": "禮炮",        "zh-cn": "礼炮"            },
//    '5': {      "en": "Disposables",            "zh-hk": "消耗品",              "zh-cn": "消耗品"            },
    '5.1': {    "en": "You are what you eat",   "zh-hk": "人如其食",            "zh-cn": "人如其食"            },
    '5.2': {    "en": "Pawns In Their Game?",   "zh-hk": "我們只是他們的工具嗎",    "zh-cn": "我们只是他们的工具吗" }
// ---END OF arw book K0---

};

export const messages = {
            "en": {
                // "TEXT180007": "Story",
                // "TEXT180008": "TABLE OF CONTENTS",
                // "TEXT180009": "Season %s",
                "TEXT180010": "Session Menu",
                "TEXT180011": "Previous Chapter",
                "TEXT180012": "Next chapter",
                // "TEXT180013": "Chapter %s",
                // "TEXT181000": "LATEST SESSION",
                // "TEXT181001": "READ FROM BEGINNING",
                // "TEXT181002": "LATEST SESSION",
                "TEXT181003": "READ NOW",

                "txtDescription": "At the end of The End Wars, all hell broke loose. Nuclear bombs dropped around the world, causing a cataclysm of apocalyptic proportions. Old borders dissolved, and city-states emerged, but were quickly put under the thumb of massive multi-national mega-corps holding the last vestiges of power from the Old World. In this Acid Rain Era, the world is dying. Can hope be found in such a place? Enter Damien Ballast, a young soldier heading to the border of his homeland for the first time. He will meet an old veteran and make new friends, and discover the mysteries of the Gray Zones.",
                "txtAuthor": "Perl Grey",
                "labelAuthor": "Author",
                "txtCreator": "Kit Lau",
                "labelCreator": "Original Story"
            },
            "zh-hk": {
                // "TEXT180007": "故事連載",
                // "TEXT180008": "故事目錄",
                // "TEXT180009": "第%s季",
                "TEXT180010": "所有章節",
                "TEXT180011": "上一章",
                "TEXT180012": "下一章",
                // "TEXT180013": "第%s章",
                // "TEXT181000": "最新章節",
                // "TEXT181001": "開始閱覽",
                // "TEXT181002": "最新章節",
                "TEXT181003": "立即閱覽",

                "txtDescription": "核彈在多國重要城市落下，毀滅性的打擊令終焉之戰落下帷幕。但更混亂的酸雨時代才剛剛開始，極端氣候災害在全球不斷蔓延。舊世界的邊界消失，新的城邦如雨後春筍般湧現，跨國企業掌控著世界的命脈，權利的紛爭與生存的挑戰衝擊著人類最後的底線。首次前往邊境服役的新兵戴緬恩，被卷入一場未知的陰謀當中。他還不知道自己要面對的，不僅僅是戰場上的辛克羅機甲和致命污染的戈雷區，還可能會揭開那掩蓋真相的黑幕。",
                "txtAuthor": "佩格雷",
                "labelAuthor": "小說作者",
                "txtCreator": "劉斯傑",
                "labelCreator": "原作"
            },
            "zh-cn": {
                // "TEXT180007": "故事连载",
                // "TEXT180008": "故事目录",
                // "TEXT180009": "第%s季",
                "TEXT180010": "所有章节",
                "TEXT180011": "上一章",
                "TEXT180012": "下一章",
                // "TEXT180013": "第%s章",
                // "TEXT181000": "最新章节",
                // "TEXT181001": "开始阅览",
                // "TEXT181002": "最新章节",
                "TEXT181003": "立即阅览",

                "txtDescription": "核弹在多国重要城市落下，毁灭性的打击令终焉之战落下帷幕。但更混乱的酸雨时代才刚刚开始，极端气候灾害在全球不断蔓延。旧世界的边界消失，新的城邦如雨后春笋般涌现，跨国企业掌控着世界的命脉，权利的纷争与生存的挑战冲击着人类最后的底线。 首次前往边境服役的新兵戴缅恩，被卷入一场未知的阴谋当中。他还不知道自己要面对的，不仅仅是战场上的辛克罗机甲和致命污染的戈雷区，还可能会揭开那掩盖真相的黑幕。",
                "txtAuthor": "佩格雷",
                "labelAuthor": "小说作者",
                "txtCreator": "刘斯杰",
                "labelCreator": "原作"
            }
        };
